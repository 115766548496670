 @keyframes kenBurnsEffect {
  0% {
    transform: scale(1) translate(0, 0);
  }
  25% {
    transform: scale(1.1) translate(-2%, -2%);
  }
  50% {
    transform: scale(1.2) translate(2%, 2%);
  }
  75% {
    transform: scale(1.1) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

.ken-burns {
  animation: kenBurnsEffect 15s infinite;
}
.fade-in {
  /* opacity: 1; */
  transition: opacity 1s ease-in-out;
}

.fade-out {
  /* opacity: 0; */
  transition: opacity 1s ease-in-out;
} 


